import React, { memo, useEffect, useMemo } from "react";
import Header from "../../components/Header";
import TopHeader from "../../components/TopHeader";
import Contactinfo from "../../components/Contactinfo";
import ColorlikHome from "../../components/ColorlikHome";
import Samplebanner from "../../components/Samplebanner";
import Categories from "../../components/Categories";
import Firstinfo from "../../components/Firstinfo";
import Outerbox from "../../Exam/Outerbox";
import Information from "../../components/Information";
import FirstFixed from "../../components/FirstFixed";
import Boxforinfo from "../../Exam/Boxforinfo";
import Whychoose from "../../components/Whychoose";
import Footer from "../../components/Footer";
import Feacter from "../../components/Feacter";
import Secondheader from "../../components/Secondheader";
import Servicebox from "../../components/Servicebox";
import Servicesertification from "../../components/Servicesertification";
import TopperHeader from "../../components/TopperHeader";
import { Box } from "@mui/material";
import Contacthome from "../../components/Contacthome";
import Shortinfobox from "../../components/Shortinfobox";
import Secondfix from "../../components/Secondfix";
import PermanentDrawerRight from "../../components/PermanentDrawerRight";
import { data } from "../../App";

function Home() {
  const changer = React.useContext(data);
  useEffect(() => {
    if (window.innerWidth > 992) {
      changer.sec("none");
    }
    window.addEventListener("resize", () => {
      changer.sec("none");
    });
    window.removeEventListener("resize", () => {});
    window.scrollTo({ top: "0", behavior: "smooth" });
  }, []);
  return (
    <>
      {/* <Secondheader/> */}

      {/* <TopHeader/> */}
      <Samplebanner />
      <Shortinfobox />
      {/* <Firstinfo/> */}
      {/* <Contactinfo/> */}
      <Information />
      <Contacthome />
      <ColorlikHome />
      <FirstFixed />
      <Feacter />
      <Categories />
      <Secondfix />
      <Whychoose />
      {/* <Servicebox/> */}
      {/* <Servicesertification/> */}
      {/* <Outerbox/> */}
    </>
  );
}

export default memo(Home);
