import React, { useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Categoriesservice() {
  const menu = [
    {
      name: "Eco-Friendly Paint",
      content:
        "Eco-friendly paint, low in VOCs and made from sustainable materials, enhances indoor air quality while minimizing environmental impact. A responsible choice for healthier, greener living spaces.",
      img: "../photo/vv1.jpg",
    },
    {
      name: "Exterior & interior Paints",
      content:
        "Versatile paints for both exterior and interior applications. Elevate curb appeal with durable, weather-resistant exterior paints, and transform indoor spaces with a wide range of stylish interior options.",
      img: "../photo/vv2.jpg",
    },
    {
      name: "Interior paint",
      content:
        "Interior paints designed to transform your living spaces. Explore a variety of colors and finishes for a personalized touch that enhances the ambiance and aesthetics of your home.",
      img: "../photo/vv3.jpg",
    },
    {
      name: "Certified by Govt of india",
      content:
        "Khadi Prakritik Paint, based in Bidar, Karnataka, stands out as a reputable paint manufacturer specializing in top-notch products like Paints, Emulsion, Distemper, Wall care putty, White Primer, and decorative solutions.",
      img: "../photo/vv4.jpg",
    },
    {
      name: "Interior & Exterior Paints",
      content:
        "Versatile paints suitable for both interior and exterior applications, providing a seamless transition from indoor to outdoor aesthetics while maintaining durability and style.",
      img: "../photo/vv5.jpg",
    },
    {
      name: "Emulsion Exterior Paint",
      content:
        "Emulsion exterior paint offers a durable and stylish solution for outdoor surfaces. Its weather-resistant properties ensure long-lasting protection while providing a smooth and attractive finish.",
      img: "../photo/vv6.jpg",
    },
    {
      name: "Available in all sizes",
      content:
        "Our products are available in all sizes, ensuring convenience and flexibility for your specific needs. Find the perfect fit for your project with our diverse size options.",
      img: "../photo/vv7.jpg",
    },
    {
      name: "BIS CERTIFIED PAINT",
      content:
        "Our paint is BIS certified, ensuring compliance with the highest standards set by the Bureau of Indian Standards for quality, safety, and performance.",
      img: "../photo/vv8.jpg",
    },
    {
      name: "We have 1600 Colors ",
      content:
        "Choose from a palette of 1600 colors to find the perfect shade for your project. Our diverse range offers endless possibilities for personalized and vibrant design solutions.",
      img: "../photo/vv9.jpg",
    },
    {
      name: "Wood Paints",
      content:
        "Transform and protect wood surfaces with our specialized wood paints. Offering a variety of colors and finishes, our paints enhance the natural beauty of wood while ensuring durability.",
      img: "../photo/vv10.jpg",
    },
  ];
  const [num, setNum] = useState(10);
  const [sty, setSty] = useState("flex");
  const menus = menu.slice(0, num);
  return (
    <>
      <Box
        mt={11}
        mb={7}
        pb={0}
        sx={{
          backgroundImage: 'url("../photo/firstbanner.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingLeft: { lg: "50px", md: "0px", sm: "10px", xs: "0px" },
          paddingRight: { lg: "50px", md: "0px", sm: "10px", xs: "0px" },
        }}
      >
        <Container maxWidth={"xl"}>
          <Box sx={{ m: 0 }}>
            <Typography variant="h4" mb={4}>
              Paint Categories
            </Typography>
            {/* <Typography fontSize={16} mb={3} variant='h6'>Fill the form below to book a free site evaluation by an Asian Paints Safe Painting Service expert.</Typography> */}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {menus.map((value, index) => (
                <Grid mb={1} item lg={4} md={6} xs={12} sm={6}>
                  <Box sx={{}}>
                    <Box
                      sx={{
                        position: "relative",
                        height: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          color: "white",
                          backgroundColor: "#282c3468",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          bottom: "0px",
                        }}
                      >
                        <Box p={1}>
                          <Typography variant="h6" component="div">
                            {value.name}
                          </Typography>
                          <Typography
                            sx={{ color: "white" }}
                            fontWeight={600}
                            pt={0}
                            variant="body2"
                            color="text.secondary"
                          >
                            {value.content}
                          </Typography>
                          <Button
                            fontWeight={600}
                            sx={{ color: "white" }}
                            size="small"
                          >
                            BUY{" "}
                          </Button>
                        </Box>
                      </Box>

                      <img style={{ width: "100%" }} src={value.img} alt="" />
                    </Box>

                    {/* <CardContent> */}

                    {/* </CardContent> */}
                    {/* <CardActions > */}
                    {/* </CardActions> */}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Categoriesservice;
