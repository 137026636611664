import { Box } from '@mui/material'
import React from 'react'

const Samplebanner = () => {
  return (<>
  <Box width={"100%"}>
    <img style={{width:"100%"}} src="../photo/sample-banner.jpg" alt="" />
  </Box>
  </>)
}

export default Samplebanner
