import React, { useEffect } from "react";
import AboutBanner from "./AboutBanner";
import Information from "../components/Information";
import Shortinfobox from "../components/Shortinfobox";
import Contacthome from "../components/Contacthome";
import Secondfix from "../components/Secondfix";
import Categories from "../components/Categories";
import Whychoose from "../components/Whychoose";
import { data } from "../App";

function About() {
  const changer = React.useContext(data);
  useEffect(() => {
    if (window.innerWidth > 992) {
      changer.sec("none");
    }
    window.addEventListener("resize", () => {
      changer.sec("none");
    });
    window.removeEventListener("resize", () => {});
    window.scrollTo({ top: "0", behavior: "smooth" });
  }, []);
  return (
    <>
      <AboutBanner />
      <Information />
      <Contacthome />
      <Whychoose />
    </>
  );
}

export default About;
