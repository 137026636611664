import React from 'react'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function Subinformation() {
  return (
    <Box sx={{ width: '100%',p:0, backgroundColor:"white",borderRadius:"5px" }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={3}>
            <Box sx={{border: "1px solid green", width:"100%",aspectRatio:1/1, borderRadius:"50%", backgroundColor:"green", display:"flex", alignItems:"center", justifyContent:"center" }}>
                <TaskAltIcon fontWeight={100} sx={{color:"white",fontSize:"50px", fontWeight:100}}/>
            </Box>
        </Grid>
        <Grid item xs={9}>
            <Box sx={{width:"100%", height:"100%", display:"flex", alignItems:"center"}}>
                
            <Box>
                
                <Typography variant='h6' fontSize={12} fontWeight={600} sx={{color:"gray"}}>Earning</Typography>
                <Typography variant='h6' fontWeight={600}>$198k</Typography>
                <Box sx={{display:"flex"}}>
                    <ArrowUpwardIcon sx={{fontSize:16, marginRight:"2px"}}/>
                    <Typography mb={0} fontSize={12}  sx={{mr:'2px',color:"red"}} paragraph>37.8% </Typography>
                    <Typography mb={0} fontSize={12} paragraph> this month</Typography>
                </Box>
                </Box>
            </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Subinformation
