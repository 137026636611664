import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";

function Contactinfo() {
  let name =
    "Turn your dream home into reality with the guidance of our skilled painting experts.";
  return (
    <>
      <Box
        sx={{ pt: "40px", pb: "40px", backgroundColor: "rgb(233, 255, 233)" }}
      >
        <Container maxWidth="xl">
          <Box sx={{ m: 0 }}>
            <Typography variant="h4">{name}</Typography>
            <Typography paragraph sx={{ marginTop: "14px" }}>
              We, Khadi Prakritik Paint, located at Bidar, Karnataka, are a
              renowned paint manufacturer offering quality Paints , Emulsion,
              Distemper, Wall care putty, White Primer, decorative sectors. Our
              main objective is to carry on the manufacturing of all kinds of
              paints such as Emulsion, Wall care putty, White Primer, Ceiling
              White, Roof care, epoxy, waterproof, etc. Moreover, we strive our
              best to become a trusted, eco-friendly company that helps to
              protect the environment and save resources and energy. Contact us
              today!
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12} sm={6} lg={3}>
              <TextField
                id="filled-multiline-flexible"
                label="Name"
                multiline
                maxRows={4}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={6} lg={3}>
              <TextField
                id="filled-multiline-flexible"
                label="Phone"
                multiline
                maxRows={4}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={6} lg={3}>
              <TextField
                id="filled-multiline-flexible"
                label="Message"
                multiline
                maxRows={4}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={6} lg={3}>
              <TextField
                id="filled-multiline-flexible"
                label="Pincode"
                multiline
                maxRows={4}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
          <Toolbar
            style={{ padding: "10px 0px" }}
            pl={0}
            sx={{
              p: 0,
              display: { lg: "flex", md: "flex", xs: "block" },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ paddingLeft: "0px" }}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Yes, I am open to receiving crucial updates and notifications via WhatsApp."
              />
              <Typography
                paragraph
                sx={{
                  marginTop: { lg: "0px", md: "0px", xs: "5px" },
                  fontSize: "14px",
                }}
              >
                By proceeding, you grant permission to Khadi Prakritik Paint and
                its recommended contractors to contact you via calls, SMS, or
                email.
              </Typography>
            </Box>
            <Box sx={{ paddingLeft: "0px" }}>
              <Button
                sx={{
                  width: "100%",
                  minWidth: "230px",
                  backgroundColor: "#1a593a",
                }}
                variant="contained"
                disableElevation
              >
                Get consultation
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </Box>
    </>
  );
}

export default Contactinfo;
