import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Feacter() {
  const menu = [
    {
      name: "Khadi prakritik paint",
      content: "Min. Qty: 10 Units",
      img: "../photo/B790C7F5127CBA7D65FF3D0B2B_1677330922232.webp",
    },
  ];
  let first =
    "Experience personalized interior designs executed with professional precision";
  return (
    <>
      <Box
        my={0}
        mt={8}
        py={4}
        sx={{
          backgroundImage: 'url("../photo/video-background-ful.png")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingLeft: { lg: "50px", md: "0px", sm: "10px", xs: "0px" },
          paddingRight: { lg: "50px", md: "0px", sm: "10px", xs: "0px" },
        }}
      >
        <Container maxWidth={"xl"}>
          {/* <Box sx={{m:0}}> 
        <Typography variant='h4' mb={4}>Featured Products</Typography>
        </Box>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item lg={12} md={6} xs={12}sm={12} >
        {menu.map((value, index) => (
            <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item lg={7} md={6} sm={6} xs={12}>
        <Box sx={{}}>
           
           <Box sx={{position:"relative",height:"100%",textAlign:"center",boxSizing:"border-box"}}>
       <Typography sx={{position:"absolute",width:"100%",color:"white",backgroundColor:"#282c3468",paddingTop:"8px",paddingBottom:"8px",bottom:"0px"}} gutterBottom variant="h6" component="div">
         {value.name}
       </Typography> 
             <img style={{width:"100%"}} src="../photo/homepage-wall-covering-W046D295S75-asian-paints.jpg" alt="" />
           </Box>
         </Box>
        </Grid>
        <Grid item sx={{backgroundColor:"",display:"flex",alignItems:"center",justifyContent:"center"}} lg={5} md={6} sm={6} xs={12}>
          <Box>
          <Typography pb={0.2} sx={{borderBottom:"2px solid gray"}} variant='h6'>Specifications</Typography>
          <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box pt={0.8}>
          <Typography py={0.2} variant='p'>Brand</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box pt={0.8}>
          <Typography variant='p'>	Kashi organics</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
          <Typography pt={0.2} variant='p'>Manufacturer Name</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
          <Typography variant='p'>Prashant soppanna</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
          <Typography variant='p'>Manufacturer Address</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
          <Typography variant='p'>Contact : 9986200268</Typography>
          </Box>
        </Grid>
        
        <Grid item xs={6}>
          <Box>
          <Typography variant='p'>Min. Qty</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
          <Typography variant='p'>10 Units</Typography>
          </Box>
        </Grid>
      </Grid>
      <Typography pt={2} pb={0.2} sx={{borderBottom:"2px solid gray"}} variant='h6'>Description</Typography>
      <Typography pt={0.5} paragraph>We have quality products</Typography>
      <Typography fontWeight={600} pt={0} pb={0} sx={{borderBottom:"0px solid gray"}} variant='h6'>1500 Rs</Typography>
    </Box>
          </Box>
          
        </Grid>
      </Grid>
    </Box>
          
        ))}
        </Grid>
      </Grid>
    </Box> */}

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid
                sx={{
                  display: { lg: "block", md: "none", sm: "none", xs: "none" },
                }}
                item
                lg={4}
                md={6}
                sm={12}
                xs={12}
              >
                <Box
                  sx={{
                    paddingLeft: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                    paddingRight: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      height: "100%",
                      textAlign: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography
                      sx={{
                        position: "absolute",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#282c3468",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        bottom: "0px",
                      }}
                      gutterBottom
                      variant="h6"
                      component="div"
                    >
                      {/* {value.name} */}INTERIOR PAINT
                    </Typography>
                    <img
                      style={{ width: "100%" }}
                      src="../photo/fea1.jpg"
                      alt=""
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    paddingLeft: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                    paddingRight: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "green",
                      fontSize: "28px",
                      lineHeight: "40px",
                      fontWeight: 600,
                    }}
                    mb={2}
                    variant="h6"
                  >
                    FEATURED PRODUCTS
                  </Typography>
                  <Box
                    sx={{
                      position: "relative",
                      height: "100%",
                      textAlign: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography
                      sx={{
                        position: "absolute",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#282c3468",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        bottom: "0px",
                      }}
                      gutterBottom
                      variant="h6"
                      component="div"
                    >
                      {/* {value.name} */}EXTERIOR PAINT
                    </Typography>
                    <img
                      style={{ width: "100%" }}
                      src="../photo/fea2.jpg"
                      alt=""
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    paddingLeft: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                    paddingRight: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      backgroundColor: "",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    lg={12}
                    md={6}
                    sm={6}
                    xs={12}
                  >
                    <Box>
                      <Typography
                        pb={1}
                        sx={{
                          borderBottom: "2px solid gray",
                          color: "red",
                          fontWeight: "700",
                        }}
                        variant="h6"
                      >
                        Specifications
                      </Typography>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Box pt={2}>
                              <Typography py={0.2} variant="p">
                                Brand
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box pt={2}>
                              <Typography variant="p">
                                {" "}
                                Kashi organics
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box>
                              <Typography pt={0.2} variant="p">
                                Manufacturer Name
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box>
                              <Typography variant="p">
                                Prashant soppanna
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box>
                              <Typography variant="p">
                                Manufacturer Address
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box>
                              <Typography variant="p">
                                Contact : 9986200268
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box>
                              <Typography variant="p">Min. Qty</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box>
                              <Typography variant="p">10 Units</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Typography
                          pt={2}
                          pb={0.2}
                          sx={{
                            borderBottom: "2px solid gray",
                            color: "red",
                            fontWeight: "700",
                          }}
                          variant="h6"
                        >
                          Description
                        </Typography>
                        <Typography pt={2} paragraph>
                          We have quality products
                        </Typography>
                        <Typography
                          fontWeight={600}
                          pt={0}
                          pb={0}
                          sx={{ borderBottom: "0px solid gray" }}
                          variant="h6"
                        >
                          1500 Rs
                        </Typography>
                        <Button
                          sx={{
                            width: "100%",
                            height: "50px",
                            marginTop: "10px",
                          }}
                          variant="contained"
                          disableElevation
                        >
                          Get consultation
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  {/* <Button sx={{width:"100%",height:"50px",bottom:"0px"}} variant="contained" disableElevation>
      Contact Us
    </Button> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Feacter;
