import React, { useEffect } from "react";
import Categoriesbanner from "./Categoriesbanner";
import Categoriesservice from "./Categoriesservice";
import Contacthome from "../components/Contacthome";
import { Box } from "@mui/material";
import { data } from "../App";

function Categories() {
  const changer = React.useContext(data);
  useEffect(() => {
    if (window.innerWidth > 992) {
      changer.sec("none");
    }
    window.addEventListener("resize", () => {
      changer.sec("none");
    });
    window.removeEventListener("resize", () => {});
    window.scrollTo({ top: "0", behavior: "smooth" });
  }, []);
  return (
    <>
      <Categoriesbanner />
      <Categoriesservice />
      <Box sx={{ marginTop: "60px", marginBottom: "60px" }}>
        <Contacthome />
      </Box>
    </>
  );
}

export default Categories;
