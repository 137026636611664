import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Outlet, Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Footer() {
  return (
    <Box
      mt={-5}
      sx={{ backgroundColor: "rgb(233, 255, 233)", paddingBottom: "30px" }}
    >
      <Container maxWidth={"xl"}>
        <Box mb={2} px={2}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid
                item
                p={0}
                xs={12}
                md={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  backgroundColor: "#1a593a",
                }}
                style={{ padding: "0px" }}
              >
                <Typography
                  sx={{ color: "white" }}
                  variant="h6"
                  fontWeight={600}
                  mb={0}
                >
                  Prakritik Paint
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography
                  paragraph
                  sx={{
                    padding: {
                      lg: "20px 20px 20px 20px",
                      md: "20px 20px 20px 20px",
                      sm: "0px 20px 20px 20px",
                      xs: "0px 0px 20px 0px",
                    },
                  }}
                  mb={0}
                >
                  Khadi Prakritik Paint, situated in Bidar, Karnataka, is a
                  well-known paint manufacturer specializing in quality products
                  such as Paints, Emulsion, Distemper, Wall care putty, White
                  Primer, and decorative solutions.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              flexGrow: 1,
              borderBottom: "2px solid gray",
              paddingBottom: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={2.4} md={3} sm={6} xs={12}>
                <Typography my={1} variant="h6" fontWeight={500}>
                  Page
                </Typography>
                <Link to="/" style={{ textDecoration: "none", color: "black" }}>
                  <Typography my={1}>Home</Typography>
                </Link>
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Typography my={1}>About</Typography>
                </Link>
                <Link
                  to="/product"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Typography my={1}>Product</Typography>
                </Link>
                <Link
                  to="/categories"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Typography my={1}>Categories</Typography>
                </Link>
                <Link to="/" style={{ textDecoration: "none", color: "black" }}>
                  <Typography my={1}>Gallery</Typography>
                </Link>
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Typography my={1}>Contact Us</Typography>
                </Link>
                <Typography my={1} variant="h6" fontWeight={500}>
                  Featured Products
                </Typography>
                <Typography my={1}>Khadi prakritik paint</Typography>
              </Grid>
              <Grid item lg={2.4} md={3} sm={6} xs={12}>
                <Typography my={1} variant="h6" fontWeight={500}>
                  Categories
                </Typography>
                <Typography my={1}>Eco-Friendly Paint</Typography>
                <Typography my={1}>Exterior & interior Paints</Typography>
                <Typography my={1}>Interior paint</Typography>
                <Typography my={1}>Certified by Govt of india</Typography>
                <Typography my={1}>Interior & Exterior Paints</Typography>
                <Typography my={1}>Emulsion Exterior Paint</Typography>
                <Typography my={1}>Available in all sizes</Typography>
                <Typography my={1}>We have 1600 Colors</Typography>
                <Typography my={1}>Wood Paints</Typography>
              </Grid>
              <Grid item lg={2.4} md={3} sm={6} xs={12}>
                <Typography my={1} variant="h6" fontWeight={500}>
                  Contact Info
                </Typography>
                <Box py={0.4} sx={{ display: "flex" }}>
                  <LocationOnIcon
                    sx={{
                      fontSize: "20px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  />
                  <Typography my={0}>
                    1st floor Omkar Swamy Complex,Opp Deepak Theater,Deepak
                    Theatre Road, Bidar HO, Bidar, Karnataka 585401
                  </Typography>
                </Box>
                <Box py={0.4} sx={{ display: "flex" }}>
                  <EmailIcon
                    sx={{
                      fontSize: "20px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  />
                  <Typography my={0}>websupport@justdial.com</Typography>
                </Box>
                <Box py={0.4} sx={{ display: "flex" }}>
                  <CallIcon
                    sx={{
                      fontSize: "20px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  />
                  <Typography my={0}>7899200268</Typography>
                </Box>
                <Box py={0.4} sx={{ display: "flex" }}>
                  <CallIcon
                    sx={{
                      fontSize: "20px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  />
                  <Typography my={0}>9986200268</Typography>
                </Box>
                <Box py={0.4} sx={{ display: "flex" }}>
                  <AccessTimeIcon
                    sx={{
                      fontSize: "20px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  />
                  <Typography my={0}>
                    Mon - Sun : 09:30 AM - 09:30 PM / 12:00 AM - 12:00 AM
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4.8} md={3} sm={6} xs={12}>
                <Button
                  sx={{ width: "100%", backgroundColor: "#1a593a" }}
                  variant="contained"
                >
                  MAP
                </Button>
                <Box mt={1}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7592.885264251488!2d77.51235669109629!3d17.911497382271147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcec7276ad2e743%3A0x13f43d4bc91308d9!2sNandi%20Colony%2C%20Bidar%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1698220558538!5m2!1sen!2sin"
                    style={{ width: "100%", height: "280px", border: "0px" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
