import { Container, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Contacthome() {
  let first =
    "Experience personalized interior designs executed with professional precision";
  return (
    <Box
      mt={2}
      sx={{
        paddingRight: { lg: "50px", md: "0px", sm: "10px", xs: "0px" },
        paddingLeft: { lg: "50px", md: "0px", sm: "10px", xs: "0px" },
      }}
    >
      <Container maxWidth={"xl"}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item lg={7} md={6} sm={12} xs={12}>
              <img width={"100%"} src="../photo/vv01.jpg" alt="" />
            </Grid>
            <Grid
              sx={{ backgroundColor: "#f8f8f8" }}
              item
              lg={5}
              md={6}
              sm={12}
              xs={12}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Box p={5}>
                  <Typography fontSize={16} mb={1} variant="h6">
                    {first.toUpperCase()}
                  </Typography>
                  <Typography
                    mb={1}
                    fontWeight={600}
                    fontSize={28}
                    variant="h5"
                  >
                    Enjoy a complimentary design consultation at no cost.
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Button variant="outlined">Explore More</Button>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Contacthome;
