import React from 'react'
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Servicesertification() {
  const menu=[{
    name:"Khadi prakritik paint",
    content:"Min. Qty: 10 Units",
    img:"../photo/B790C7F5127CBA7D65FF3D0B2B_1677330922232.webp"
  },
  {
    name:"Khadi prakritik paint",
    content:"Min. Qty: 10 Units",
    img:"../photo/B790C7F5127CBA7D65FF3D0B2B_1677330922232.webp"
  },
]

  return (<>
    <Box my={0} py={5} sx={{backgroundImage :'url("../photo/firstbanne.jpg")', backgroundSize:"cover",backgroundPosition:"center", paddingLeft:{lg:"50px",md:"0px", sm:"10px",xs:"0px"},paddingRight:{lg:"50px",md:"0px", sm:"10px",xs:"0px"}}}>
      <Container maxWidth={"xl"}>
        <Box sx={{m:0}}> 
        <Typography variant='h4' mb={4}>Featured Products</Typography>
        {/* <Typography fontSize={16} mb={3} variant='h6'>Fill the form below to book a free site evaluation by an Asian Paints Safe Painting Service expert.</Typography> */}
        </Box>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {menu.map((value, index) => (
          <Grid item lg={6} md={6} xs={12}sm={12} >
            <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
        <Box sx={{}}>
           
           <Box sx={{position:"relative",height:"100%",textAlign:"center",boxSizing:"border-box"}}>
       <Typography sx={{position:"absolute",width:"100%",color:"white",backgroundColor:"#282c3468",paddingTop:"8px",paddingBottom:"8px",bottom:"0px"}} gutterBottom variant="h6" component="div">
         {value.name}
       </Typography> 
             <img style={{width:"100%"}} src={value.img} alt="" />
           </Box>
       <Typography fontWeight={600} pt={1} variant="body2" color="text.secondary">
         {value.content.slice(0,100)}
       </Typography>
         </Box>
        </Grid>
        <Grid item  lg={6} md={6} sm={6} xs={12}>
          <Typography py={1} sx={{borderBottom:"2px solid gray"}} variant='h6'>Specifications</Typography>
          <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box pt={1}>
          <Typography py={1} variant='p'>Brand</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box pt={1}>
          <Typography variant='p'>	Kashi organics</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
          <Typography variant='p'>Manufacturer Name</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
          <Typography variant='p'>Prashant soppanna</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
          <Typography variant='p'>Manufacturer Address</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
          <Typography variant='p'>Contact : 9986200268</Typography>
          </Box>
        </Grid>
      </Grid>
      <Typography pt={2} pb={1} sx={{borderBottom:"2px solid gray"}} variant='h6'>Description</Typography>
      <Typography pt={1} paragraph>We have quality products</Typography>
      <Typography fontWeight={600} pt={0} pb={1} sx={{borderBottom:"0px solid gray"}} variant='h6'>1500 Rs</Typography>
    </Box>
        </Grid>
      </Grid>
    </Box>
          
          </Grid>
        ))}
      </Grid>
    </Box>
      </Container>
    </Box>
  </>
  )
}

export default Servicesertification