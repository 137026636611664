import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Shortinfobox() {
  return (
    <Box
      sx={{
        display: {
          lg: "block",
          md: "none",
          sm: "none",
          xs: "none",
          backgroundColor: "#bb1b1a",
        },
      }}
      py={0}
    >
      <Container maxWidth={"xl"}>
        <Box mt={1.2} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid sx={{ borderRight: "3px solid white" }} item xs={6} md={3}>
              <Typography
                pb={1.5}
                ml={1}
                sx={{ fontSize: "20px", color: "white", textAlign: "center" }}
                fontWeight={600}
                variant="h6"
              >
                Diverse Product Range
              </Typography>
            </Grid>
            <Grid sx={{ borderRight: "3px solid white" }} item xs={6} md={3}>
              <Typography
                sx={{ fontSize: "20px", color: "white", textAlign: "center" }}
                fontWeight={600}
                variant="h6"
              >
                Mastery in Premium Solutions
              </Typography>
            </Grid>
            <Grid sx={{ borderRight: "3px solid white" }} item xs={6} md={3}>
              <Typography
                sx={{ fontSize: "20px", color: "white", textAlign: "center" }}
                fontWeight={600}
                variant="h6"
              >
                Located in Bidar, Karnataka
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ fontSize: "20px", color: "white", textAlign: "center" }}
                fontWeight={600}
                variant="h6"
              >
                Environmental Commitment
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Shortinfobox;
