import logo from "./logo.svg";
import "./App.css";
import Home from "./Page/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import TopperHeader from "./components/TopperHeader";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contactinfo from "./components/Contactinfo";
import About from "./Page/About";
import Product from "./Page/Product";
import Categories from "./Page/Categories";
import Contact from "./Page/Contact";
import { createContext, useState } from "react";
const data = createContext();

function App() {
  const [dis, setDis] = useState("none");
  const obj = {
    fir: dis,
    sec: setDis,
  };
  return (
    <>
      <data.Provider value={obj}>
        <BrowserRouter>
          <Box
            sx={{
              backgroundImage:
                "linear-gradient(rgba(0, 0, 0, 0.501),rgba(0, 0, 0, 0))",
              position: "fixed",
              width: "100%",
              zIndex: "999",
            }}
          >
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "rgba(0, 0, 0, 0.189)",
                zIndex: "999",
                position: "absolute",
                display: dis,
              }}
            ></Box>
            <TopperHeader />
            <Header />
          </Box>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/product" element={<Product />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Contactinfo />
          <Footer />
        </BrowserRouter>
      </data.Provider>
    </>
  );
}

export default App;
export { data };
