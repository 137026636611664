import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

function Information() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <>
      <Box
        py={9}
        sx={{
          backgroundImage: 'url("../photo/video-background-full.png")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingLeft: { lg: "30px", md: "30px", sm: "20px", xs: "0px" },
          paddingRight: { lg: "30px", md: "30px", sm: "20px", xs: "0px" },
        }}
      >
        <Container maxWidth={"xl"}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    paddingLeft: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                    paddingRight: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <Typography
                    mb={2}
                    sx={{ color: "red", fontWeight: "700" }}
                    variant="h6"
                  >
                    MAKE NEW COLOR SELECTIONS THIS SEASON
                  </Typography>
                  <Typography mb={2} sx={{ fontWeight: "600" }} variant="h4">
                    Choosing A Paint Color Has Never Been Easier
                  </Typography>
                  <Typography mb={4} paragraph>
                    Khadi Prakritik Paint, based in Bidar, Karnataka, stands out
                    as a reputable paint manufacturer specializing in top-notch
                    products like Paints, Emulsion, Distemper, Wall care putty,
                    White Primer, and decorative solutions. Our primary goal is
                    to excel in the production of a wide range of paints,
                    including Emulsion, Wall care putty, White Primer, Ceiling
                    White, Roof care, epoxy, waterproof, and more. Furthermore,
                    we are dedicated to establishing ourselves as a reliable,
                    environmentally conscious company committed to safeguarding
                    the planet by conserving resources and energy. Get in touch
                    with us today!
                  </Typography>
                  {/* <Button sx={{width:"100%",height:"50px",bottom:"0px"}} variant="contained" disableElevation>
      Contact Us
    </Button> */}
                </Box>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    paddingLeft: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                    paddingRight: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                  }}
                >
                  <Typography mt={2} paragraph>
                    Begin your journey to find the perfect paint colors
                    effortlessly by using our simple color engine. Our paint
                    visualizer offers a stunning platform to craft inspiration
                    boards, making the process of choosing excellent colors a
                    breeze.
                  </Typography>
                  <Box
                    sx={{
                      position: "relative",
                      height: "100%",
                      textAlign: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography
                      sx={{
                        position: "absolute",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#282c3468",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        bottom: "0px",
                      }}
                      gutterBottom
                      variant="h6"
                      component="div"
                    >
                      {/* {value.name} */}ECO-FRIENDLY PAINT
                    </Typography>
                    <img
                      style={{ width: "100%" }}
                      src="../photo/in01.jpg"
                      alt=""
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                sx={{
                  display: { lg: "block", md: "none", sm: "none", xs: "none" },
                }}
                item
                lg={4}
                md={6}
                sm={12}
                xs={12}
              >
                <Box
                  sx={{
                    paddingLeft: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                    paddingRight: {
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "0px",
                    },
                  }}
                >
                  <Button
                    sx={{ width: "100%", height: "50px", marginBottom: "30px" }}
                    variant="contained"
                    disableElevation
                  >
                    Get consultation
                  </Button>
                  <Box
                    sx={{
                      position: "relative",
                      height: "100%",
                      textAlign: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography
                      sx={{
                        position: "absolute",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#282c3468",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        bottom: "0px",
                      }}
                      gutterBottom
                      variant="h6"
                      component="div"
                    >
                      {/* {value.name} */}EXTERIOR & INTERIOR PAINT
                    </Typography>
                    <img
                      style={{ width: "100%" }}
                      src="../photo/in02.jpg"
                      alt=""
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Information;
