import React from 'react'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Button, Container, Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Servicebox() {
    const menu=[{
        name:"Eco-Friendly Paint",
        content:"Eco-friendly paint, low in VOCs and made from sustainable materials, enhances indoor air quality while minimizing environmental impact. A responsible choice for healthier, greener living spaces.",
        img:"../photo/jj1.png"
      },
      {
        name:"Exterior & interior Paints",
        content:"Versatile paints for both exterior and interior applications. Elevate curb appeal with durable, weather-resistant exterior paints, and transform indoor spaces with a wide range of stylish interior options.",
        img:"../photo/jj2.png"
      },
      {
        name:"Interior paint",
        content:"Interior paints designed to transform your living spaces. Explore a variety of colors and finishes for a personalized touch that enhances the ambiance and aesthetics of your home.",
        img:"../photo/jj3.png"
      },
      {
        name:"Certified by Govt of india",
        content:"Khadi Prakritik Paint, based in Bidar, Karnataka, stands out as a reputable paint manufacturer specializing in top-notch products like Paints, Emulsion, Distemper, Wall care putty, White Primer, and decorative solutions.",
        img:"../photo/jj4.png"
      },
      {
        name:"Interior & Exterior Paints",
        content:"Versatile paints suitable for both interior and exterior applications, providing a seamless transition from indoor to outdoor aesthetics while maintaining durability and style.",
        img:"../photo/jj5.png"
      },
      {
        name:"Emulsion Exterior Paint",
        content:"Emulsion exterior paint offers a durable and stylish solution for outdoor surfaces. Its weather-resistant properties ensure long-lasting protection while providing a smooth and attractive finish.",
        img:"../photo/jj6.png"
      },
      {
        name:"Available in all sizes",
        content:"Our products are available in all sizes, ensuring convenience and flexibility for your specific needs. Find the perfect fit for your project with our diverse size options.",
        img:"../photo/jj7.png"
      },
      {
        name:"BIS CERTIFIED PAINT",
        content:"Our paint is BIS certified, ensuring compliance with the highest standards set by the Bureau of Indian Standards for quality, safety, and performance.",
        img:"../photo/jj8.png"
      },
      {
        name:"We have 1600 Colors ",
        content:"Choose from a palette of 1600 colors to find the perfect shade for your project. Our diverse range offers endless possibilities for personalized and vibrant design solutions.",
        img:"../photo/jj9.png"
      },
      {
        name:"Wood Paints",
        content:"Transform and protect wood surfaces with our specialized wood paints. Offering a variety of colors and finishes, our paints enhance the natural beauty of wood while ensuring durability.",
        img:"../photo/jj10.png"
      },
    ]
  return (<>
  <Box>
    <Container maxWidth={"xl"}  sx={{backgroundImage :'url("../photo/firstbanner.jpg")', backgroundSize:"cover",backgroundPosition:"center", paddingLeft:{lg:"80px",md:"0px", sm:"10px",xs:"0px"},paddingRight:{lg:"80px",md:"0px", sm:"10px",xs:"0px"}}}>
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {menu.map((value,index ) => (

<Grid item lg={4} md={4} xs={12}sm={6} sx={{}}>
<Box sx={{marginBottom:"22px"}}>
 
  <Box sx={{position:"relative",height:"100%",textAlign:"center",boxSizing:"border-box"}}>
<Typography sx={{position:"absolute",width:"100%",color:"white",backgroundColor:"#282c3468",paddingTop:"8px",paddingBottom:"8px",bottom:"0px"}} gutterBottom variant="h6" component="div">
{value.name}
</Typography> 
    <img style={{width:"100%"}} src={value.img} alt="" />
  </Box>

{/* <CardContent> */}
<Box px={1} pb={1}>
<Typography fontWeight={600} pt={1} variant="body2" color="text.secondary">
{value.content}
</Typography>
{/* </CardContent> */}
{/* <CardActions > */}
<Box sx={{display:"flex", justifyContent:"space-between"}}>
    
<Button size="small"></Button>
<Button sx={{backgroundColor:"red", color:"white",marginBottom:"0px",marginTop:"8px"}}
onMouseOver={(e) => (e.target.style.backgroundColor = 'green')}
onMouseOut={(e) => (e.target.style.backgroundColor = 'red')} size="small">BUY </Button>
</Box>
</Box>
{/* </CardActions> */}
</Box>
</Grid>
))}
      </Grid>
    </Box>
    </Container>
  </Box>
  </>
  )
}

export default Servicebox
