import { Box, Button, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import styled from "styled-components";

const pages = [
  "ECO-FRIENDLY PAINT",
  "EXTERIOR & INTERIOR PAINT",
  "CERTIFIED BY GOVT OF INDIA",
  "BIS CERTIFIED PAINT",
];
const pagess = ["7899200268"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Bottona = styled(Button)({
  paddingLeft: "0px",
  fontSize: "12px",
});

const TopperHeader = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box pt={1.2} sx={{}}>
      <Container maxWidth={"xl"}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              flexGrow: 1,
              right: "0px",
              display: "flex",
            }}
          >
            {pages.map((page) => (
              <Bottona
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  mt: 0,
                  color: "white",
                  display: "block",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "18px",
                  fontSize: "13px",
                  display: { xs: "none", lg: "flex" },
                }}
                pl={0}
                style={{ paddingLeft: "0px" }}
              >
                {page}
              </Bottona>
            ))}
          </Box>
          <Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", sm: "flex" },
                right: "0px",
              }}
            >
              <Bottona
                onClick={handleCloseNavMenu}
                sx={{
                  mt: 0,
                  color: "white",
                  display: { sm: "block", xs: "none" },
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "18px",
                  fontSize: "13px",
                }}
                pl={0}
                style={{ paddingLeft: "0px" }}
              >
                websupport@justdial.com
              </Bottona>
              <Bottona
                onClick={handleCloseNavMenu}
                sx={{
                  mt: 0,
                  color: "white",
                  display: "block",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingRight: "18px",
                  fontSize: "13px",
                }}
                pl={0}
                style={{ paddingLeft: "0px" }}
              >
                7899200268
              </Bottona>
              <TwitterIcon
                sx={{ color: "white", paddingRight: "6px", fontSize: "18px" }}
              />
              <FacebookIcon
                sx={{ color: "white", paddingRight: "6px", fontSize: "18px" }}
              />
              <LocationOnIcon
                sx={{ color: "white", paddingRight: "0px", fontSize: "18px" }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TopperHeader;
