import React, { memo, useMemo } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Stack } from "@mui/material";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ContactsIcon from "@mui/icons-material/Contacts";
import { useDispatch } from "react-redux";
import { fetchTodos } from "../redux/slice/todo";
import { Outlet, Link } from "react-router-dom";
import PermanentDrawerRight from "./PermanentDrawerRight";
import { data } from "../App";

const pages = [
  ["HOME", "/"],
  ["ABOUT", "/about"],
  ["PRODUCT", "/product"],
  ["CATEGORIES", "/categories"],
  // ["SERVICES", "/services"],
  ["CONTACT", "/contact"],
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function Header() {
  const changer = React.useContext(data);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const dispatch = useDispatch();

  return (
    <>
      <PermanentDrawerRight />
      <AppBar
        px={2}
        onClick={(e) => dispatch(fetchTodos())}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0)",
          boxShadow: "0px 0px 0px white",
          paddingTop: "5px",
          paddingBottom: "5px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        position="static"
      >
        <Container maxWidth="" sx={{ backgroundColor: "#f8f8f8" }}>
          <Toolbar sx={{ width: "100%" }} disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 0,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <img
                  style={{ width: "220px" }}
                  src="../photo/pra-logo.png"
                  alt=""
                />
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ paddingLeft: "0px" }}
              >
                <MenuIcon
                  style={{ color: "black" }}
                  sx={{ color: "black" }}
                  onClick={() => {
                    changer.sec("flex");
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <AdbIcon sx={{ display: { xs: "none", md: "none" }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 0,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img
                style={{ width: "140px" }}
                src="../photo/pra-logo.png"
                alt=""
              />
            </Typography>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}

                <Stack spacing={2} direction="row">
                  {/* <Button sx={{backgroundColor:"#1c5a3c", display:{lg:"block",md:"block",sm:"none",xs:"none"}}} variant="contained">Contained</Button> */}

                  <Box
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", md: "flex" },
                      right: "0px",
                    }}
                  >
                    {pages.map((page, index) => (
                      <Button
                        key={page}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "gray", display: "block" }}
                      >
                        <Link
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "gray",
                            textDecoration: "none",
                          }}
                          to={page[1]}
                        >
                          {page[0]}
                        </Link>
                      </Button>
                    ))}
                  </Box>
                  <ContactsIcon
                    sx={{
                      display: {
                        lg: "none",
                        md: "none",
                        sm: "block",
                        xs: "block",
                      },
                      color: "black",
                    }}
                  />
                </Stack>
                {/* </IconButton> */}
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default memo(Header);
