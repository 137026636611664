import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function FirstFixed() {
  let name =
    "We are just a phone call away, ready to address all your painting needs.";

  return (
    <Box
      pt={12}
      pb={11}
      sx={{
        backgroundImage: 'url("../photo/secondbanner.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid
              sx={{ backgroundColor: "white", marginBottom: "0px" }}
              item
              lg={8}
              md={8}
              sm={8}
              xs={12}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    padding: {
                      lg: "30px 46px 46px 30px",
                      md: "30px 46px 46px 30px",
                      sm: "30px 46px 46px 30px",
                      xs: "30px 16px 46px 0px",
                    },
                  }}
                >
                  <Typography variant="h5" mb={2} fontWeight={600}>
                    {name.toUpperCase()}
                  </Typography>
                  <Typography mb={2} paragraph>
                    Selecting the finest paint colors is effortless with our
                    user-friendly color engine. Our paint visualizer offers a
                    stunning method to build inspiration boards, simplifying the
                    process of choosing exceptional colors.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                      <TextField
                        id="filled-multiline-flexible"
                        label="Name"
                        multiline
                        maxRows={4}
                        variant="filled"
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                      <TextField
                        id="filled-multiline-flexible"
                        label="Message"
                        multiline
                        maxRows={4}
                        variant="filled"
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                      <Button
                        sx={{
                          width: "100%",
                          height: "57px",
                          marginBottom: "30px",
                        }}
                        variant="contained"
                        disableElevation
                      >
                        Get consultation
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography mt={2} mb={0} paragraph>
                    To choose the best paint colors effortlessly, begin with a
                    straightforward color engine.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default FirstFixed;
