import React, { useEffect } from "react";
import Feacter from "../components/Feacter";
import Categories from "../components/Categories";
import ProductBanner from "./ProductBanner";
import Contacthome from "../components/Contacthome";
import { Box } from "@mui/material";
import Whychoose from "../components/Whychoose";
import FirstFixed from "../components/FirstFixed";
import { data } from "../App";

function Product() {
  const changer = React.useContext(data);
  useEffect(() => {
    if (window.innerWidth > 992) {
      changer.sec("none");
    }
    window.addEventListener("resize", () => {
      changer.sec("none");
    });
    window.removeEventListener("resize", () => {});
    window.scrollTo({ top: "0", behavior: "smooth" });
  }, []);
  return (
    <>
      <ProductBanner />
      <Feacter />
      <Categories />
      {/* <Box sx={{ marginTop: "60px", marginBottom: "30px" }}>
        <Contacthome />
      </Box> */}
      <Box sx={{ marginTop: "-50px" }}>
        <Whychoose />
      </Box>
    </>
  );
}

export default Product;
