import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Categories() {
  const color = [
    {
      first: [
        {
          name: "Yellow",
          color: "#fbca16",
          number: "7862",
        },
        {
          name: "Greek Vase",
          color: "#6aafd9",
          number: "7328",
        },
        {
          name: "Gulabi",
          color: "#ca6ea1",
          number: "X178",
        },
        // {
        //   name: "Goldfish",
        //   color: "#f67e32",
        //   number: "X115",
        // },
      ],
    },
    {
      first: [
        {
          name: "Cambridge",
          color: "#d9cfc5",
          number: "8771",
        },
        {
          name: "Serene gray",
          color: "#abaaa8",
          number: "K274",
        },
        {
          name: "Dove blue",
          color: "#a1c5e7",
          number: "9648",
        },
        // {
        //   name: "Green",
        //   color: "#e7f2d0",
        //   number: "9686",
        // },
      ],
    },
    {
      first: [
        {
          name: "Blue vision",
          color: "#77c1ce",
          number: "7432",
        },
        {
          name: "Laal",
          color: "#cd4a50",
          number: "X179",
        },
        {
          name: "Yogi",
          color: "#ef8241",
          number: "X174",
        },
        // {
        //   name: "Fresh Olive",
        //   color: "#829041",
        //   number: "7725",
        // },
      ],
    },
  ];
  return (
    <>
      <Box
        pt={7}
        pb={9}
        sx={{
          backgroundImage: 'url("../photo/firstbanner.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingLeft: { lg: "50px", md: "0px", sm: "10px", xs: "0px" },
          paddingRight: { lg: "50px", md: "0px", sm: "10px", xs: "0px" },
        }}
      >
        <Container maxWidth={"xl"}>
          <Box sx={{ m: 0 }}>
            <Typography
              sx={{
                paddingLeft: { lg: "0px", md: "0px", sm: "0px", xs: "0px" },
              }}
              variant="h4"
              mb={4}
            >
              Paint Color Picker
            </Typography>
            {/* <Typography fontSize={16} mb={3} variant='h6'>Fill the form below to book a free site evaluation by an Asian Paints Safe Painting Service expert.</Typography> */}
          </Box>
          <Box mb={4} sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <img style={{ width: "100%" }} src="../photo/a1.jpg" alt="" />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {color.map((value, index) => (
                <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                  <Box sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    {/* <Box sx={{position:"relative",height:"100%",textAlign:"center",boxSizing:"border-box"}}>
        <Typography sx={{position:"absolute",width:"100%",color:"white",backgroundColor:"#282c3468",paddingTop:"8px",paddingBottom:"8px",bottom:"0px"}} gutterBottom variant="h6" component="div">
          Lizard
        </Typography> 
              <img style={{width:"100%"}} src="../photo/homepage-crosslink-blogs-5-wall-colours-for-home-with-a-calming-influence-asian-paints.jpg" alt="" />
            </Box> */}

                    {/* <CardContent> */}
                    {/* <Typography fontWeight={600} pt={1} variant="body2" color="text.secondary">
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across
        </Typography> */}
                    {/* </CardContent> */}
                    <Grid container spacing={2}>
                      {value.first.map((val) => {
                        let valer = val.name;
                        let valer2 = valer.split(" ");
                        return (
                          <Grid item xs={3}>
                            <Item
                              sx={{
                                aspectRatio: 1 / 0.5,
                                backgroundColor: val.color,
                              }}
                            ></Item>
                            <Typography mt={1} fontSize={14} variant="h6">
                              {valer2[0]}...
                            </Typography>
                            <Typography mt={0} fontSize={14} variant="h6">
                              {val.number}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box>
            {/* <Stack pt={3} sx={{display:"flex", justifyContent:"right"}} spacing={2} direction="row">
      <Button href="../pdf/Prakritik.pdf" variant="outlined">More Color</Button>
    </Stack> */}
          </Box>
          <Grid mt={5} container spacing={2}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box>
                <Button
                  sx={{
                    borderRadius: "20px",
                    marginBottom: "10px",
                    boxShadow: "0px 0px 0px black",
                  }}
                  variant="contained"
                >
                  Diverse Product Range
                </Button>
              </Box>
              <Typography sx={{ color: "#1976d2" }} variant="p">
                Khadi Prakritik Paint, headquartered in Bidar, Karnataka, is
                renowned for its extensive product portfolio. Specializing in
                top-notch items such as Paints, Emulsion, Distemper, Wall care
                putty, White Primer, and decorative solutions, the company
                offers a comprehensive range of options to meet various painting
                and decorating needs.
              </Typography>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box>
                <Button
                  sx={{
                    borderRadius: "20px",
                    marginBottom: "10px",
                    boxShadow: "0px 0px 0px black",
                  }}
                  variant="contained"
                >
                  Quality Commitment
                </Button>
              </Box>
              <Typography sx={{ color: "#1976d2" }} variant="p">
                Khadi Prakritik Paint excels in producing a diverse range of
                high-quality paints, including Emulsion, Wall care putty, White
                Primer, Ceiling White, Roof care, epoxy, waterproof, and more.
              </Typography>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box>
                <Button
                  sx={{
                    borderRadius: "20px",
                    marginBottom: "10px",
                    boxShadow: "0px 0px 0px black",
                  }}
                  variant="contained"
                >
                  Environmental Responsibility
                </Button>
              </Box>
              <Typography sx={{ color: "#1976d2" }} variant="p">
                Beyond product excellence, Khadi Prakritik Paint is committed to
                being an environmentally conscious company. With a focus on
                safeguarding the planet, the company actively participates in
                resource and energy conservation initiatives.
              </Typography>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box>
                <Button
                  sx={{
                    borderRadius: "20px",
                    marginBottom: "10px",
                    boxShadow: "0px 0px 0px black",
                  }}
                  variant="contained"
                >
                  Reliability and Accessibility
                </Button>
              </Box>
              <Typography sx={{ color: "#1976d2" }} variant="p">
                Khadi Prakritik Paint positions itself as a reliable partner for
                customers seeking premium paint solutions. By prioritizing
                customer satisfaction, the company encourages individuals and
                businesses to get in touch.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
export default Categories;
