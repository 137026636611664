import React from "react";

function Categoriesbanner() {
  return (
    <section className="carslider">
      <div className="allabout contacterercat">
        <div style={{ textAlign: "center" }}>
          <div style={{ display: "flex" }}>
            <h1
              style={{
                marginRight: "10PX",
                color: "#1a593a",
                marginBottom: "0px",
              }}
            >
              Prakritik{" "}
            </h1>
            <h1 style={{ color: "#bb1b1a", marginBottom: "0px" }}> Paint</h1>
          </div>
          <p style={{ color: "white", marginTop: "10px" }}>
            ECO - FRIENDLY COE DUNG PAINT
          </p>
        </div>
      </div>
    </section>
  );
}

export default Categoriesbanner;
