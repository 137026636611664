import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Firstinfo() {
  return (<>
  <Box>
    <Container maxWidth="xl">
        
    <Box sx={{ flexGrow: 1, my:7 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={7}>
            <img width={"100%"} src="../photo/longim.png" alt="" />
        </Grid>
        <Grid item xs={6} md={5}>
          <Typography paragraph>The error message suggests that a loop of ResizeObserver notifications completed, but some of the notifications weren't delivered. This might happen if you're trying to observe an element that's being manipulated or removed before the observer can deliver all notifications.</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
            <img width={"100%"} src="../photo/qw12.jpg" alt="" />
        </Grid>
        <Grid item xs={6} md={4}>
            <img width={"100%"} src="../photo/qw12.jpg" alt="" />
        </Grid>
        <Grid item xs={6} md={4}>
            <img width={"100%"} src="../photo/qw12.jpg" alt="" />
        </Grid>
      </Grid>
    </Box>
    </Container>
  </Box>
  </>)
}

export default Firstinfo
