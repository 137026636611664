import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import { Outlet, Link } from "react-router-dom";
import { Button, List } from "@mui/material";
import { data } from "../App";

const drawerWidth = 260;

export default function PermanentDrawerLeft() {
  const changer = React.useContext(data);
  return (
    <>
      <Box
        sx={{ display: changer.fir, width: "100vw", backgroundColor: "white" }}
      >
        <CssBaseline />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Divider />
          <List>
            {[
              ["HOME", "/"],
              ["ABOUT", "/about"],
              ["PRODUCT", "/product"],
              ["CATEGORIES", "/categories"],
              // ["SERVICES", "/services"],
              ["CONTACT", "/contact"],
            ].map((text, index) => (
              <Link
                to={text[1]}
                style={{ textDecoration: "none", color: "black" }}
                onClick={() => {
                  changer.sec("none");
                }}
              >
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon style={{ maxWidth: "32px" }}>
                      <FindInPageIcon
                        style={{ maxWidth: "32px" }}
                        sx={{ maxWidth: "32px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{ marginLeft: "-20px" }}
                      primary={text[0]}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CallIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-20px" }}
                  primary="7899200268"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-20px" }}
                  primary="websupport@justdial.com"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <EditLocationIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-20px" }}
                  primary="1st floor Omkar Swamy Complex,Opp Deepak Theater,Deepak Theatre Road, Bidar HO, Bidar, Karnataka 585401"
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <Box
            sx={{
              padding: "10px 10px 10px 10px",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                changer.sec("none");
              }}
            >
              Cancel
            </Button>
          </Box>
          <Divider />
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 0,
            bgcolor: "background.default",
            p: 0,
            position: "absolute",
            zIndex: "1000",
            height: "100vh",
          }}
        ></Box>
      </Box>
    </>
  );
}
