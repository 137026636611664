import React, { useEffect } from "react";
import ContactBanner from "./ContactBanner";
import Secondfix from "../components/Secondfix";
import Information from "../components/Information";
import Contacthome from "../components/Contacthome";
import { Box } from "@mui/material";
import Contactinfo from "../components/Contactinfo";
import Contactboxerinfo from "./Contactboxerinfo";
import { data } from "../App";

function Contact() {
  const changer = React.useContext(data);
  useEffect(() => {
    if (window.innerWidth > 992) {
      changer.sec("none");
    }
    window.addEventListener("resize", () => {
      changer.sec("none");
    });
    window.removeEventListener("resize", () => {});
    window.scrollTo({ top: "0", behavior: "smooth" });
  }, []);
  return (
    <>
      <ContactBanner />
      <Contactboxerinfo />
      <Box sx={{ marginBottom: "60px" }}>
        <Contacthome />
      </Box>
      <Secondfix />
    </>
  );
}

export default Contact;
