import { Button, Container, TextField, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Secondfix() {
  let name = "We are just a phone call away";
  return (
    <Box
      py={7}
      sx={{
        backgroundImage: 'url("../photo/BG-Index-Main.png")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid
              sx={{ backgroundColor: "", marginBottom: "0px" }}
              item
              lg={8}
              md={12}
              sm={12}
              xs={12}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    padding: {
                      lg: "30px 46px 46px 30px",
                      md: "30px 46px 46px 30px",
                      sm: "30px 46px 46px 30px",
                      xs: "30px 16px 46px 0px",
                    },
                  }}
                >
                  <Typography
                    sx={{ color: "white" }}
                    variant="h5"
                    mb={1.5}
                    fontWeight={600}
                  >
                    {name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "yellow",
                      fontSize: "36px",
                      lineHeight: "44px",
                      fontWeight: 600,
                    }}
                    mb={2}
                    variant="h6"
                  >
                    Selecting the most exquisite and captivating paint hues
                  </Typography>
                  <Typography
                    sx={{ color: "#1976d2" }}
                    variant="h5"
                    mb={2}
                    fontWeight={600}
                  >
                    How to get in touch!
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={4} sm={6} xs={12}>
                      <Box>
                        <Button
                          sx={{
                            borderRadius: "20px",
                            marginBottom: "10px",
                            boxShadow: "0px 0px 0px black",
                          }}
                          variant="contained"
                        >
                          Address
                        </Button>
                      </Box>
                      <Typography sx={{ color: "white" }} variant="p">
                        1st floor Omkar Swamy Complex,Opp Deepak Theater,Deepak
                        Theatre Road, Bidar HO, Bidar, Karnataka 585401
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Box>
                        <Button
                          sx={{
                            borderRadius: "20px",
                            marginBottom: "10px",
                            boxShadow: "0px 0px 0px black",
                          }}
                          variant="contained"
                        >
                          Phone
                        </Button>
                      </Box>
                      <Typography sx={{ color: "white" }} variant="p">
                        7899200268
                      </Typography>
                      <Box>
                        <Typography sx={{ color: "white" }} variant="p">
                          9986200268
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid
              sx={{ display: "flex", alignItems: "center" }}
              item
              lg={4}
              md={12}
              sm={12}
              xs={12}
            >
              <Box p={2} sx={{ backgroundColor: "white" }}>
                <Typography variant="h6" mb={2} fontWeight={600}>
                  Contact Us
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="filled-multiline-flexible"
                      label="Name"
                      multiline
                      maxRows={4}
                      variant="filled"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="filled-multiline-flexible"
                      label="Message"
                      multiline
                      maxRows={4}
                      variant="filled"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      sx={{
                        width: "100%",
                        height: "57px",
                        marginBottom: "0px",
                      }}
                      variant="contained"
                      disableElevation
                    >
                      Get consultation
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Secondfix;
