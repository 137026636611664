import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Whychoose() {
  useEffect(() => {
    let tokens;
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://localhost:4000/api/v1/logout",
          {
            email: "sobhsdjbbdvhjhjyak@gmail.com",
            password: "password",
          }
        );

        tokens = response.data;
        console.log(tokens);
      } catch (error) {
        console.error(
          "Error during registration:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchData();
  }, []);

  //  useEffect(() => {
  //   const fetchData = async () => {
  //     const config = {
  //       headers: { "Content-Type": "application/json" },
  //     };
  //     try {
  //       const response = await axios.post("http://localhost:4000/api/v1/admin/createproduct", {
  //         name:"pens",
  //         description:"hdfv sdcvbjsjh sdvhbjhl dfvdjbd",
  //         price:"hdcjds",
  //         ratings:4,
  //         images:{
  //           public_id:"jxhcb",
  //           url:"sxxhj"
  //         },
  //         catagory:"vhvvhj",
  //         reviews:[]
  //       },{
  //         cookies:{
  //           token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1MzlmMjUzNzJkOTE2YjY5ODM3YzIyOSIsImlhdCI6MTY5ODI5NjQwMywiZXhwIjoxNjk4NzI4NDAzfQ.TbnfDBHxJhKeGP7L4oMH8oqi1Z_nD3za_DuDc8IuVnA"
  //         }
  //       }
  //       );

  //       console.log("Registration successful:", response.data.user);

  //     } catch (error) {
  //       console.error("Error during registration:", error.response ? error.response.data : error.message);
  //     }
  //   };

  //   fetchData();
  // }, []);
  const name = {
    cookies: {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1MzlmMjUzNzJkOTE2YjY5ODM3YzIyOSIsImlhdCI6MTY5ODI5NjQwMywiZXhwIjoxNjk4NzI4NDAzfQ.TbnfDBHxJhKeGP7L4oMH8oqi1Z_nD3za_DuDc8IuVnA",
    },
  };
  console.log(name.cookies.token);

  const [value, setValue] = useState(5);
  return (
    <Box
      pt={9}
      sx={{
        backgroundImage: 'url("../photo/video-backround-full.png")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingLeft: { lg: "50px", md: "0px", sm: "10px", xs: "0px" },
        paddingRight: { lg: "50px", md: "0px", sm: "10px", xs: "0px" },
      }}
    >
      <Container maxWidth={"xl"}>
        <Box sx={{ m: 0 }}>
          <Typography pl={0} variant="h4" mb={2}>
            Testimonials
          </Typography>
          {/* <Typography fontSize={16} mb={3} variant='h6'>Fill the form below to book a free site evaluation by an Asian Paints Safe Painting Service expert.</Typography> */}
        </Box>
        <Box pt={2} pb={7} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={4} xs={12} sm={6}>
              <Box>
                <Box
                  p={0}
                  sx={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                      position: "absolute",
                      width: "100%",
                      color: "white",
                      backgroundColor: "#282c3468",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      bottom: "0px",
                    }}
                  >
                    <Rating name="read-only" value={value} readOnly />
                  </Box>
                  <img
                    style={{ width: "100%" }}
                    src="../photo/firstimage.jpg"
                    alt=""
                  />
                </Box>
                <Typography pt={0.8} fontWeight={600} variant="h6">
                  Senthil D K, Bengalore
                </Typography>

                <Typography pt={0.8} paragraph>
                  Khadi Prakritik Paints stood out for me with their vibrant
                  color range and exceptional durability. My home looks
                  stunning, and I'm impressed with the long-lasting finish.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} xs={12} sm={6}>
              <Box>
                <Box
                  p={0}
                  sx={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                      position: "absolute",
                      width: "100%",
                      color: "white",
                      backgroundColor: "#282c3468",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      bottom: "0px",
                    }}
                  >
                    <Rating name="read-only" value={value} readOnly />
                  </Box>
                  <img
                    style={{ width: "100%" }}
                    src="../photo/re1.jpg"
                    alt=""
                  />
                </Box>
                <Typography pt={0.8} fontWeight={600} variant="h6">
                  Chetan Desai, Bagalkot
                </Typography>

                <Typography pt={0.8} paragraph>
                  The diverse range of products, from Emulsion to Wall care
                  putty, gives me plenty of options for various projects. It's a
                  one-stop-shop for all my painting needs.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} xs={12} sm={6}>
              <Box>
                <Box
                  p={0}
                  sx={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                      position: "absolute",
                      width: "100%",
                      color: "white",
                      backgroundColor: "#282c3468",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      bottom: "0px",
                    }}
                  >
                    <Rating name="read-only" value={value} readOnly />
                  </Box>
                  <img
                    style={{ width: "100%" }}
                    src="../photo/re2.jpg"
                    alt=""
                  />
                </Box>
                <Typography pt={0.8} fontWeight={600} variant="h6">
                  Sunil Kumar, Bidar
                </Typography>

                <Typography pt={0.8} paragraph>
                  As someone environmentally conscious, I appreciate Khadi
                  Prakritik Paint's commitment to being eco-friendly. Using
                  their products makes me feel good about contributing to a
                  greener planet.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} xs={12} sm={6}>
              <Box>
                <Box
                  p={0}
                  sx={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                      position: "absolute",
                      width: "100%",
                      color: "white",
                      backgroundColor: "#282c3468",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      bottom: "0px",
                    }}
                  >
                    <Rating name="read-only" value={value} readOnly />
                  </Box>
                  <img
                    style={{ width: "100%" }}
                    src="../photo/er34.jpg"
                    alt=""
                  />
                </Box>
                <Typography pt={0.8} fontWeight={600} variant="h6">
                  Sunil Kumar, Bidar
                </Typography>

                <Typography pt={0.8} paragraph>
                  Khadi Prakritik Paint's eco-friendly products align with my
                  commitment to environmental consciousness, enhancing my
                  surroundings and contributing to a greener planet.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Whychoose;
